import React, { Component } from "react";

class HeroTitle extends Component {
  render() {
    return (
      <h1 className="text-4xl tracking-tight font-extrabold text-slate-900 sm:text-5xl md:text-6xl">
        <span className="block">{this.props.line1}</span>{' '}
        <span className="block text-blue-600">{this.props.line2}</span>
      </h1>
    )
  }
}

export default HeroTitle;
