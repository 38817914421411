import React, { Component } from "react";
import { SpeakerphoneIcon, GlobeAltIcon, CloudIcon, DeviceMobileIcon, PhoneIcon, MailIcon, ThumbUpIcon, CheckIcon } from '@heroicons/react/outline';
import Layout from "../layout";
import Blog from "../sections/blog";
import SquarePhoto1 from "../images/code.svg";
import SoftwareEngineerPhoto from "../images/software-engineer.svg";
import { Link } from 'gatsby';
import FeaturedImage from '../images/featured-index.jpeg';
import Hero from "../sections/hero";
import HeroTitle from "../components/hero-title";

const features = [
  {
    name: 'Website development',
    description:
      'We have helped non-IT organizations and companies to improve their business performance and sales in online through websites.',
    icon: GlobeAltIcon,
  },
  {
    name: 'Mobile development',
    description:
      'Our UX and design teams make sure that your app is easy to use, accessible and effective wherever your user is.',
    icon: DeviceMobileIcon,
  },
  {
    name: 'Cloud solutions',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: CloudIcon,
  },
  {
    name: 'Digital marketing',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: SpeakerphoneIcon,
  },
]

const product = {
  name: 'Basic Tee 6-Pack',
  price: '$192',
  href: '#',
  breadcrumbs: [
    { id: 1, name: 'Men', href: '#' },
    { id: 2, name: 'Clothing', href: '#' },
  ],
  images: [
    {
      src: 'https://tailwindui.com/img/ecommerce-images/product-page-02-secondary-product-shot.jpg',
      alt: 'Two each of gray, white, and black shirts laying flat.',
    },
    {
      src: 'https://tailwindui.com/img/ecommerce-images/product-page-02-tertiary-product-shot-01.jpg',
      alt: 'Model wearing plain black basic tee.',
    },
    {
      src: 'https://tailwindui.com/img/ecommerce-images/product-page-02-tertiary-product-shot-02.jpg',
      alt: 'Model wearing plain gray basic tee.',
    },
    {
      src: 'https://tailwindui.com/img/ecommerce-images/product-page-02-featured-product-shot.jpg',
      alt: 'Model wearing plain white basic tee.',
    },
  ],
  description:
    'The Basic Tee 6-Pack allows you to fully express your vibrant personality with three grayscale options. Feeling adventurous? Put on a heather gray tee. Want to be a trendsetter? Try our exclusive colorway: "Black". Need to add an extra pop of color to your outfit? Our white tee has you covered.',
  highlights: [
    'Hand cut and sewn locally',
    'Dyed with our proprietary colors',
    'Pre-washed & pre-shrunk',
    'Ultra-soft 100% cotton',
  ],
  details:
    'The 6-Pack includes two black, two white, and two heather gray Basic Tees. Sign up for our subscription service and be the first to get new, exciting colors, like our upcoming "Charcoal Gray" limited release.',
}
class IndexPage extends Component {
  render() {
    return (
      <Layout>
        <Hero image={FeaturedImage}>
          <HeroTitle line1="We're tech partner of" line2="Your Innovations" />
          <p className="mt-3 text-base text-slate-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            Vinkas is a provider of IT consulting and software development services. We have helped organizations and companies improve business performance &amp; enhance their competitiveness.
          </p>
          <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <div className="rounded-md shadow">
              <a
                href="#"
                className="btn-lg text-white bg-blue-600 hover:bg-blue-700"
              >
                <PhoneIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Call us
              </a>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <Link
                to="/contact"
                className="btn-lg text-blue-700 bg-blue-100 hover:bg-blue-200"
              >
                <MailIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Send message
              </Link>
            </div>
          </div>
        </Hero>

        <div className="row bg-slate-100">
          <div className="container with-padding relative">
            <svg class="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-0" width="404" height="490" fill="none" viewBox="0 0 404 490">
              <defs>
                <pattern id="svg-pattern-squares-3" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-slate-200" fill="currentColor"></rect>
                </pattern>
              </defs>
              <rect width="404" height="784" fill="url(#svg-pattern-squares-3)"></rect>
            </svg>
            <div class="relative">
              <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                <div class="lg:col-start-2">
                  <h4 class="text-2xl font-extrabold text-slate-900 tracking-tight sm:text-3xl">
                    Small Teams can Make a Big Impact
                  </h4>
                  <p class="mt-3 text-lg text-slate-500">
                    Our team goals are improved work output, better communication, increased morale, and more satisfied customers. 
                  </p>

                  <ul class="mt-10">
                    <li>
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <div class="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                            <ThumbUpIcon className="h-6 w-6" />
                          </div>
                        </div>
                        <div class="ml-4">
                          <h5 class="text-lg leading-6 font-medium text-slate-900">We attract the best</h5>
                          <p class="mt-2 text-base text-slate-500">
                            Like attracts like. We have spent years building high-performance software applications and are known for quality.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mt-10">
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <div class="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                            <CheckIcon className="h-6 w-6" />
                          </div>
                        </div>
                        <div class="ml-4">
                          <h5 class="text-lg leading-6 font-medium text-slate-900">Future perfect solutions</h5>
                          <p class="mt-2 text-base text-slate-500">
                            You are not just investing money rather in your brand, identity, and business process. We think of your product like ours.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                  <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
                    <defs>
                      <pattern id="svg-pattern-squares-4" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4" class="text-slate-200" fill="currentColor"></rect>
                      </pattern>
                    </defs>
                    <rect width="784" height="404" fill="url(#svg-pattern-squares-4)"></rect>
                  </svg>
                  <img class="relative mx-auto" width="490" src={SoftwareEngineerPhoto} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row bg-blue-600">
          <div className="container with-padding">
            <div className="grid items-center grid-cols-1 gap-y-16 gap-x-8 lg:grid-cols-2">
              <div>
                <h2 className="text-3xl font-extrabold tracking-tight text-blue-50 sm:text-4xl">Improve your online presence</h2>
                <p className="mt-4 text-blue-100">
                  Do you have a clear vision of what you want to achieve?
                </p>
                <p className="mt-1 text-blue-200">
                  We have the expertise, insight and experience to build a tailored software solution to get you there.<br /><br />
                  We create leading web and mobile apps for our clients. Our front-end, backend, server and database solutions help businesses reach their full potential by improving existing processes, overcoming challenges and making the most of new opportunities.
                </p>

                <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                  {features.map((feature) => (
                    <div key={feature.name} className="border-t border-blue-500 pt-4">
                      <dt className="font-medium text-lg text-blue-50">{feature.name}</dt>
                      <dd className="mt-2 text-blue-200">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
              <div className="">
                <img
                  src={SquarePhoto1}
                  alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
                />
              </div>
            </div>
          </div>
        </div>

        <div className="py-12 bg-white">
          <div className="container with-padding">
            <div className="lg:text-center">
              <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Technologies</h2>
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-slate-900 sm:text-4xl">
                We provide innovative tech solutions
              </p>
              <p className="mt-4 max-w-2xl text-xl text-slate-500 lg:mx-auto">
                We created excellent and sustainable technology solutions based on IT domain expertise.
                Stop worrying, we take care of your technology problems.
              </p>
            </div>

            <div className="mt-10">
              <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                {features.map((feature) => (
                  <div key={feature.name} className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                        <feature.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-slate-900">{feature.name}</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-slate-500">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className="pt-16 lg:pt-24 lg:grid lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr]">
              <div className="lg:col-span-2 lg:pr-8">
                <h1 className="text-2xl font-extrabold tracking-tight text-slate-900 sm:text-3xl">{product.name}</h1>
              </div>

              <div className="mt-4 lg:mt-0 lg:row-span-3">
                <h2 className="sr-only">Product information</h2>

                <div className="aspect-w-4 aspect-h-5 sm:rounded-lg sm:overflow-hidden lg:aspect-w-3 lg:aspect-h-4">
                  <img
                    src={product.images[3].src}
                    alt={product.images[3].alt}
                    className="w-full h-full object-center object-cover"
                  />
                </div>
              </div>

              <div className="py-10 lg:pt-6 lg:pb-16 lg:col-start-1 lg:col-span-2 lg:pr-8">
                {/* Description and details */}
                <div>
                  <h3 className="sr-only">Description</h3>

                  <div className="space-y-6">
                    <p className="text-base text-slate-900">{product.description}</p>
                  </div>
                </div>

                <div className="mt-10">
                  <h3 className="font-medium text-slate-900">Highlights</h3>

                  <div className="mt-4">
                    <ul role="list" className="pl-4 list-disc space-y-2">
                      {product.highlights.map((highlight) => (
                        <li key={highlight} className="text-slate-400">
                          <span className="text-slate-600">{highlight}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="mt-10">
                  <h2 className="font-medium text-slate-900">Details</h2>

                  <div className="mt-4 space-y-6">
                    <p className="text-slate-600">{product.details}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row bg-slate-800">
          <div class="container with-padding">
            <div class="max-w-4xl mx-auto text-center">
              <h2 class="text-3xl font-extrabold text-white sm:text-4xl">
                Trusted by developers from over 80 planets
              </h2>
              <p class="mt-3 text-xl text-slate-300 sm:mt-4">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellendus repellat laudantium.
              </p>
            </div>
            <div class="mt-10 text-center sm:max-w-3xl sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-8">
              <div>
                <p class="text-5xl font-extrabold text-white">
                  100%
                </p>
                <p class="mt-2 text-lg leading-6 font-medium text-slate-300">
                  Pepperoni
                </p>
              </div>
              <div class="mt-10 sm:mt-0">
                <p class="text-5xl font-extrabold text-white">
                  24/7
                </p>
                <p class="mt-2 text-lg leading-6 font-medium text-slate-300">
                  Delivery
                </p>
              </div>
              <div class="mt-10 sm:mt-0">
                <p class="text-5xl font-extrabold text-white">
                  100k+
                </p>
                <p class="mt-2 text-lg leading-6 font-medium text-slate-300">
                  Calories
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row bg-blue-800">
          <div class="md:grid md:grid-cols-2 container with-padding">
            <div class="px-4 sm:px-6 md:flex md:flex-col md:pl-0 md:pr-10 md:border-r md:border-blue-900 lg:pr-16">
              <div class="md:flex-shrink-0">
                <svg fill="none" height="40" viewBox="0 0 105 40">
                  <path fill="#B4C6FC" fill-rule="evenodd" d="M18 1L0 7v19.5l6 2V34l18 6V8.5l-6 2V1zM8 29.167L18 32.5V12.608l4-1.333v25.95L8 32.558v-3.391z" clip-rule="evenodd"></path>
                  <path fill="#B4C6FC" d="M42.9 28V17.45h-3.51v-3.392h11.486v3.393h-3.53V28H42.9zM59.481 28.254c-4.075 0-6.376-2.028-6.376-6.006v-8.19h4.407v8.014c0 1.814.39 2.71 1.97 2.71 1.56 0 1.95-.896 1.95-2.73v-7.994h4.445v8.15c0 4.193-2.496 6.046-6.396 6.046z"></path>
                  <path fill="#B4C6FC" fill-rule="evenodd" d="M68.965 14.058V28h4.407v-4.543h1.346c3.607 0 5.538-1.638 5.538-4.544v-.078c0-2.983-1.716-4.777-5.733-4.777h-5.558zm4.407 6.435h.916c1.17 0 1.775-.527 1.775-1.56v-.078c0-1.073-.605-1.502-1.755-1.502h-.936v3.14z" clip-rule="evenodd"></path>
                  <path fill="#B4C6FC" d="M82.563 14.058V28h9.497v-3.412h-5.07v-10.53h-4.427zM94.562 28V14.058h9.906v3.393h-5.499v1.97h4.368v3.1h-4.368v2.086h5.811V28H94.562z"></path>
                </svg>
              </div>
              <blockquote class="mt-8 md:flex-grow md:flex md:flex-col">
                <div class="relative text-lg font-medium text-white md:flex-grow">
                  <svg class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-blue-600" fill="currentColor" viewBox="0 0 32 32">
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"></path>
                  </svg>
                  <p class="relative">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo expedita voluptas culpa sapiente alias molestiae. Numquam corrupti in laborum sed rerum et corporis.
                  </p>
                </div>
                <footer class="mt-8">
                  <div class="flex">
                    <div class="flex-shrink-0 inline-flex rounded-full border-2 border-white">
                      <img class="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80" alt="" />
                    </div>
                    <div class="ml-4">
                      <div class="text-base font-medium text-white">Judith Black</div>
                      <div class="text-base font-medium text-blue-200">CEO, Tuple</div>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
            <div class="px-4 border-t-2 border-blue-900 sm:px-6 md:pr-0 md:pl-10 md:border-t-0 md:border-l lg:pl-16">
              <div class="md:flex-shrink-0">
                <svg fill="none" height="40" viewBox="0 0 180 40">
                  <path fill="#B4C6FC" fill-rule="evenodd" d="M34.746 13.065l7.596 4.385a7.399 7.399 0 00-2.533-4.735h4.066a7.389 7.389 0 00-6.558-2.933l5.518-3.186a7.39 7.39 0 00-6.608.397 7.363 7.363 0 00-2.467 2.316 7.366 7.366 0 00-2.468-2.316 7.388 7.388 0 00-6.607-.397l5.517 3.186a7.39 7.39 0 00-6.557 2.933h4.064a7.402 7.402 0 00-2.53 4.736l7.593-4.385v20.37H27.84V18.193l-5.096 2.942.476-3.922a9.421 9.421 0 01.671-2.525h-3.08L0 19.89l.479 1.915 3.678-.92v12.551H.21v1.974h43.416v-1.974h-8.88V13.065zM17.97 23.569h5.92v9.867h-5.92v-9.867zm-6.907 3.947a1.974 1.974 0 100-3.947 1.974 1.974 0 000 3.947z" clip-rule="evenodd"></path>
                  <path fill="#B4C6FC" d="M150.544 19.38c1.042 0 1.895-.853 1.895-1.871s-.853-1.895-1.895-1.895c-1.018 0-1.87.877-1.87 1.895a1.89 1.89 0 001.87 1.87zM149.029 32.641h3.055v-11.84h-3.055v11.84zM146.875 20.8v2.937h-2.676v4.926c0 1.279.924 1.302 2.676 1.207v2.771c-4.286.474-5.731-.781-5.731-3.978v-4.926h-2.06v-2.936h2.06v-2.392l3.055-.924v3.316h2.676zM118.495 32.973c2.321 0 4.334-1.232 5.352-3.079l-2.652-1.515c-.474.97-1.492 1.563-2.723 1.563-1.824 0-3.174-1.35-3.174-3.221 0-1.895 1.35-3.244 3.174-3.244 1.207 0 2.226.615 2.699 1.586l2.629-1.54c-.971-1.823-2.984-3.054-5.305-3.054-3.599 0-6.252 2.723-6.252 6.252 0 3.528 2.653 6.252 6.252 6.252z"></path>
                  <path fill="#B4C6FC" fill-rule="evenodd" d="M134.277 20.8v1.398c-.853-1.066-2.131-1.729-3.86-1.729-3.15 0-5.755 2.723-5.755 6.252 0 3.528 2.605 6.252 5.755 6.252 1.729 0 3.007-.663 3.86-1.729v1.397h3.055v-11.84h-3.055zm-3.292 9.26c-1.871 0-3.268-1.35-3.268-3.34 0-1.988 1.397-3.338 3.268-3.338 1.895 0 3.292 1.35 3.292 3.339 0 1.99-1.397 3.339-3.292 3.339zM166.792 26.72c0 3.53-2.795 6.253-6.276 6.253s-6.252-2.724-6.252-6.252c0-3.529 2.771-6.252 6.252-6.252s6.276 2.723 6.276 6.252zm-9.473 0c0 1.92 1.397 3.269 3.197 3.269 1.824 0 3.221-1.35 3.221-3.268 0-1.918-1.397-3.268-3.221-3.268-1.8 0-3.197 1.35-3.197 3.268z" clip-rule="evenodd"></path>
                  <path fill="#B4C6FC" d="M175.524 20.469c-1.586 0-2.818.592-3.528 1.658V20.8h-3.055v11.84h3.055v-6.394c0-2.06 1.113-2.936 2.605-2.936 1.373 0 2.344.829 2.344 2.439v6.891H180v-7.27c0-3.15-1.966-4.902-4.476-4.902zM59.267 32.642h3.718L66.087 21.7l3.126 10.94h3.718l4.642-16.576h-3.434l-3.173 12.29-3.481-12.29H64.69l-3.457 12.29-3.174-12.29h-3.433l4.641 16.576z"></path>
                  <path fill="#B4C6FC" fill-rule="evenodd" d="M83.551 32.973c3.482 0 6.276-2.723 6.276-6.252 0-3.528-2.794-6.252-6.276-6.252-3.48 0-6.252 2.724-6.252 6.252 0 3.529 2.771 6.252 6.252 6.252zm0-2.984c-1.8 0-3.197-1.35-3.197-3.268 0-1.918 1.398-3.268 3.197-3.268 1.824 0 3.221 1.35 3.221 3.268 0 1.918-1.397 3.268-3.22 3.268z" clip-rule="evenodd"></path>
                  <path fill="#B4C6FC" d="M95.031 20.8v2.037c.616-1.61 2.108-2.273 3.6-2.273v3.41c-1.587-.19-3.6.521-3.6 3.008v5.66h-3.055V20.8h3.055zM111.334 32.642l-4.902-5.992 4.76-5.85h-3.647l-4.073 5.21v-9.946h-3.055v16.578h3.055v-5.376l4.31 5.376h3.552z"></path>
                </svg>
              </div>
              <blockquote class="mt-8 md:flex-grow md:flex md:flex-col">
                <div class="relative text-lg font-medium text-white md:flex-grow">
                  <svg class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-blue-600" fill="currentColor" viewBox="0 0 32 32">
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"></path>
                  </svg>
                  <p class="relative">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo expedita voluptas culpa sapiente alias molestiae. Numquam corrupti in laborum sed rerum et corporis. Nemo expedita voluptas culpa sapiente alias molestiae.
                  </p>
                </div>
                <footer class="mt-8">
                  <div class="flex">
                    <div class="flex-shrink-0 inline-flex rounded-full border-2 border-white">
                      <img class="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80" alt="" />
                    </div>
                    <div class="ml-4">
                      <div class="text-base font-medium text-white">Judith Black</div>
                      <div class="text-base font-medium text-blue-200">CEO, Tuple</div>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
        </div>

        <div class="row bg-white">
          <div class="container with-padding">
            <h2 class="text-3xl font-extrabold tracking-tight text-slate-900 sm:text-4xl">
              Looking for a co-working space in Madurai?
              <br />
              <span class="text-blue-600">We have a best and affordable solution for you.</span>
            </h2>
            <div class="mt-8 flex">
              <div class="inline-flex rounded-md shadow">
                <a href="https://hub.vinkas.com" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                  Get started
                </a>
              </div>
              <div class="ml-3 inline-flex">
                <a href="https://hub.vinkas.com/#contact" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-blue-700 bg-blue-100 hover:text-blue-600 hover:bg-blue-50 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out">
                  Contact us
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="py-12 bg-blue-100">
          <div className="container with-padding">
            <Blog />
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
